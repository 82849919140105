var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container p-0" },
    [
      _c("transition", { attrs: { appear: "", name: "fade" } }, [
        _c("div", { staticClass: "container-fluid" }, [
          _vm.isCardLoading
            ? _c(
                "div",
                { staticClass: "card-container mx-auto my-4 bold text-center" },
                [_vm._v(" loading... ")]
              )
            : _vm._e(),
          _vm.isCardLoadError
            ? _c(
                "div",
                { staticClass: "card-container mx-auto my-4 bold text-center" },
                [_vm._v(" Could not find your card ")]
              )
            : _vm._e(),
          _vm.isCardLoaded
            ? _c(
                "div",
                { staticClass: "card-container mx-auto my-4 bold text-center" },
                [
                  _vm.isCardPending
                    ? _c("div", [
                        _vm._v(
                          " The card exists and is ready for you. Please activate it before using it (and before it expires)"
                        ),
                        _c("br"),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-md mt-3",
                            attrs: { type: "button" },
                            on: { click: _vm.activateCard }
                          },
                          [_vm._v(" Activate ")]
                        )
                      ])
                    : _vm._e(),
                  _vm.isCardActive
                    ? _c(
                        "div",
                        {
                          staticClass: "card-containers mx-auto my-4 text-left"
                        },
                        [
                          _c("h5", [
                            _vm._v(
                              "The card was activated, here is how you can use it"
                            )
                          ]),
                          _c("img", {
                            staticClass: "text-center",
                            attrs: {
                              src: _vm.imageURL,
                              width: "240",
                              height: "auto"
                            }
                          }),
                          _vm._l(_vm.redemptionCredentialsObject, function(
                            object
                          ) {
                            return _c("div", { key: object }, [
                              object.type == "url"
                                ? _c(
                                    "div",
                                    { staticClass: "redemptionObject" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(object.label) + ": ")
                                      ]),
                                      _c(
                                        "a",
                                        { attrs: { href: object.value } },
                                        [_vm._v(_vm._s(object.value))]
                                      )
                                    ]
                                  )
                                : _c("p", { staticClass: "redemptionObject" }, [
                                    _vm._v(
                                      _vm._s(object.label) +
                                        ": " +
                                        _vm._s(object.value)
                                    )
                                  ])
                            ])
                          }),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.redemptionInstructions)
                            }
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.isCardExpired
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "card-container mx-auto my-4 bold text-center"
                        },
                        [_vm._v(" The card already expired ")]
                      )
                    : _vm._e(),
                  _vm.isCardCancelled
                    ? _c("div", [_vm._v(" The card was cancelled ")])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ])
      ]),
      _c("appFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }