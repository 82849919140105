<template>
  <div class="container p-0">
    <transition appear name="fade">
      <div class="container-fluid">
        <div v-if="isCardLoading" class="card-container mx-auto my-4 bold text-center">
          loading...
        </div>
        <div v-if="isCardLoadError" class="card-container mx-auto my-4 bold text-center">
          Could not find your card
        </div>
        <div v-if="isCardLoaded" class="card-container mx-auto my-4 bold text-center">
          <div v-if="isCardPending">
            The card exists and is ready for you. Please activate it before using it (and before it expires)<br />
            <button class="btn btn-primary btn-md mt-3" type="button" v-on:click="activateCard">
              Activate
            </button>
          </div>
          <div v-if="isCardActive" class="card-containers mx-auto my-4 text-left">
            <h5>The card was activated, here is how you can use it</h5>
            <img :src="imageURL" class="text-center" width="240" height="auto" />
            <div v-for="(object) in redemptionCredentialsObject" v-bind:key="object">
              <div v-if="object.type == 'url'" class="redemptionObject">
                <span>{{object.label}}: </span>
                <a :href="object.value">{{ object.value }}</a>
              </div>
              <p class="redemptionObject" v-else>{{ object.label }}: {{ object.value }}</p>
            </div>
            <div v-html="redemptionInstructions"></div>
          </div>
          <div v-if="isCardExpired" class="card-container mx-auto my-4 bold text-center">
            The card already expired
          </div>
          <div v-if="isCardCancelled">
            The card was cancelled
          </div>
        </div>
      </div>
    </transition>
    <appFooter></appFooter>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    appHeader: Header,
    appFooter: Footer
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      value: state => state.user.card.value,
      currency: state => state.user.card.currency,
      expirationTimestamp: state => state.user.card.expirationTimestamp,
      status: state => state.user.card.status,
      referenceOrderID: state => state.user.card.referenceOrderID,
      redemptionInstructions: state => state.user.card.redemptionInstructions,
      redemptionCredentialsJson: state => state.user.card.redemptionCredentialsJson,
      redemptionCredentialsObject: state => JSON.parse(state.user.card.redemptionCredentialsJson),
      imageURL : state => state.user.card.imageURL,
      isCardLoaded: state => state.user.status.card.initialized,
      isCardLoading: state => state.user.status.card.refreshing,
      isCardLoadError: state => state.user.status.card.error,
    }),
    ...mapGetters({
      isCardPending: 'user/isCardPending',
      isCardActive: 'user/isCardActive',
      isCardExpired: 'user/isCardExpired',
      isCardCancelled: 'user/isCardCancelled',
    }),
  },
  methods: {
    activateCard: function () {
      this.$store
        .dispatch('user/activateCard', {
          code: this.code
        })
        .then(() => { },
          (err) => {
            this.showError('Unknown error')
          })
        .catch((e) => {
          console.error(e)
          this.showError('Unknown error')
        })
    },
    showError: function (message) {
      this.$bvModal.msgBoxOk(message, {
        size: 'sm',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        okVariant: 'danger',
        centered: true
      })
    }
  },
  async created() {
    try {
      await this.$store.dispatch('user/fetchCard', { code: this.$props.code })
      console.log("Found and loaded card correctly")
    }
    catch (e) {
      console.error(e)
    }
  }
}
</script>

<style lang="scss">
.card-container {
  max-width: 1000px;
}

a {
  color: $primary;
}

.redemptionObject {
  background-color: $gray-200;
  display: inline-block;
  padding: 16px;
  border-radius: 16px;
  margin-top: 24px;

}
</style>
